import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { idOrEmptyRoute } from "../utils/utils"
import { ApiClient } from "./ApiClient"
import { IdResult } from "./Common"
import { FilterWithValue, getApiDescription, getExport, getHref, getPaginated, getResource, putResource, SortCriterionWithValue } from "./Search"
import { BasicActivityGroupId } from "./WellKnowIds"

export type ActivityCategoryModel =
    {
        activityCategoryId: number
    }

export type ActivityCategoryView =
    {
        activityCategoryId: number
        name: string
    }

export type AllProductsViewModel = ActivityCategoryView &
{ classificationHints: string[] }

export const EMPTY_ACTIVITY_CATEGORY_VIEW: ActivityCategoryView =
{
    activityCategoryId: 0,
    name: '',
}

export type ActivityCategoryCustomerValidView = ActivityCategoryView & { europeanUnionCode?: string }

export type ActivitySubCategoryView = ActivityCategoryView & { isValidCustomerActivity: boolean }

export type BasicActivityGroupView =
    {
        basicActivityGroupId: number
        name: string
        abbreviation: string
    }

export type ActivityClassView =
    {
        activityClassId: number
        name: string
        isPricingClass: boolean
    }

export type ActivityCategoryBindingModel =
    {
        activityCategoryId?: number
        basicActivityGroupId: BasicActivityGroupId
        nameGr: string,
        nameEn?: string,
        nameLatin?: string
        europeanUnionCode?: string
        conversionPeriodMonths?: number
        productsGr: string[]
        variantsGr: string[]
        classIds: number[]
        isEditable?: boolean
    }

export const EMPTY_ACTIVITY_CATEGORY_BINDING: ActivityCategoryBindingModel = { basicActivityGroupId: 0 as unknown as BasicActivityGroupId, nameGr: '', productsGr: [], variantsGr: [], classIds: [] }

export type ActivityCategoryDetailed =
    {
        activityCategoryId: number
        nameGr: string
        nameEn?: string
        nameBg?: string
        nameLatin?: string
        europeanUnionCode?: string
        basicActivityGroup: string
        conversionPeriodMonths?: number
        conversionPeriodWeeks?: number
        isDeleted: boolean
        classificationTypeId?: number
        classificationType?: string
    }

export type ActivityCategoryDependencyTree =
    {
        groups: {
            basicActivityGroupId: BasicActivityGroupId
            fragments: {
                fragment: ActivityCategoryView & { europeanUnionCode?: string }
                attributes: {
                    attribute?: ActivityCategoryView
                    products: {
                        product: ActivityCategoryView
                    }[]
                    variants: {
                        variant: ActivityCategoryView
                    }[]
                }[]
            }[]
        }[]
    }

export class ActivityCategoriesApi {
    constructor(private readonly api: ApiClient) { }

    public getDependencyTree = () =>
        getResource<ActivityCategoryDependencyTree>(this.api, `api/activity-categories/dependency-tree`, { groups: [] }, { timeout: this.api.maxTimeoutMs })

    public upsertActivityCategory = (model: ActivityCategoryBindingModel) =>
        putResource<ActivityCategoryBindingModel, IdResult>(this.api, `api/activity-categories/fragments${idOrEmptyRoute(model.activityCategoryId)}`, model)

    public getActivityCategory = (id: number) =>
        getResource<ActivityCategoryBindingModel>(this.api, `api/activity-categories/fragments/${id}`, EMPTY_ACTIVITY_CATEGORY_BINDING)

    public addProductToAttribute = (attributeId: number, name: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/activity-categories/attributes/${attributeId}/products`, undefined, { name: name }),
            "Could not add product", null)

    public getSubcategories = (activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivitySubCategoryView[]>(200, 'GET', `api/activity-categories/${activityCategoryId}/subcategories`),
            "Could not load subcategories", [] as ActivitySubCategoryView[])

    public getBasicActivityGroups = () =>
        this.api.onUnexpected(
            this.api.execute<BasicActivityGroupView[]>(200, 'GET', `api/activity-categories/groups`),
            "Could not load basic activity groups", [] as BasicActivityGroupView[])

    public getActivityCategoryFragments = (basicActivityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivityCategoryCustomerValidView[]>(200, 'GET', `api/activity-categories/${basicActivityCategoryId}/fragments`),
            "Could not load activity fragments", [] as ActivityCategoryCustomerValidView[])

    public getProducts = (activitiesWithAttributes: { activityId: number, attributes: number[] }[]) => {
        const params = new URLSearchParams()
        activitiesWithAttributes.forEach(c => {
            if (c.attributes.length)
                c.attributes.forEach(a => params.append(c.activityId.toString(), a.toString()))
            else
                params.append(c.activityId.toString(), '')
        })
        return this.api.onUnexpected(
            this.api.execute<{ [fragmentId: number]: { [attributeId: number]: ActivityCategoryView[] } }>(200, 'GET', `api/activity-categories/products`, params),
            "Could not load products", null)
    }

    public getVariants = (...activitiesWithAttributes: { activityId: number, attributes: number[] }[]) => {
        const params = new URLSearchParams()
        activitiesWithAttributes.forEach(c => {
            if (c.attributes.length)
                c.attributes.forEach(a => params.append(c.activityId.toString(), a.toString()))
            else
                params.append(c.activityId.toString(), '')
        })
        return this.api.onUnexpected(
            this.api.execute<{ [fragmentId: number]: { [attributeId: number]: ActivityCategoryView[] } }>(200, 'GET', `api/activity-categories/variants`, params),
            "Could not load variants", null)
    }

    public getAttributes = (...activityCategoryIds: number[]) => {
        const params = new URLSearchParams()
        activityCategoryIds.forEach(c => params.append('categoryId', c.toString()))
        return this.api.onUnexpected(
            this.api.execute<{ [activityId: number]: ActivityCategoryView[] }>(200, 'GET', `api/activity-categories/attributes`, params),
            "Could not load attributes", null)
    }

    public getClasses = () =>
        getResource<ActivityClassView[]>(this.api, `api/activity-categories/classes`, [])

    public getAllPoducts = () =>
        this.api.onUnexpected(
            this.api.execute<AllProductsViewModel[]>(200, 'GET', `api/activity-categories/products/all`),
            "Could not load activity classes", [] as AllProductsViewModel[])

    public getAllActivityCategories = (request: HrefOrExplicitRequest) =>
        getPaginated<ActivityCategoryDetailed>(this.api, getHref(request, `api/activity-categories`))

    public getAllActivityCategoriesApi = () =>
        getApiDescription(this.api, `api/activity-categories`)

    public exportActivityCategories = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
        getExport(this.api, `api/activity-categories/export`, filters, sorting)

    public updateActivityCategories = (model: { file: File, isDryRun: boolean, sheetName: string }) => {
        const formData = new FormData()
        formData.append('file', model.file)
        formData.append('isDryRun', model.isDryRun.toString())
        formData.append('sheetName', model.sheetName)
        return putResource<FormData, { changes: { id: number, from?: string, to?: string }[], errors: string[], warnings: string[] }>(this.api, `api/activity-categories/batch`, formData, { timeout: 12 * 60000 })
    }
}